import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  userId: localStorage.getItem("userId") ? localStorage.getItem('userId') : '',
  userEmail: localStorage.getItem("userEmail") ? localStorage.getItem('userEmail') : '',
  userToken: localStorage.getItem("userToken") ? localStorage.getItem('userToken') : '',
  userName: localStorage.getItem("userName") ? localStorage.getItem('userName') : '',
  userRole: localStorage.getItem("userRole") ? localStorage.getItem('userRole') : '',
  isStatus: localStorage.getItem("isStatus") ? localStorage.getItem('isStatus') : '',
  isAdmin: localStorage.getItem("isAdmin") ? localStorage.getItem('isAdmin') : false,
  languageCode: localStorage.getItem("languageCode") ? localStorage.getItem('languageCode') : '',
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addToCart(state, action) {
      state.userId = action.payload?.userId
      state.userEmail = action.payload?.userEmail
      state.userToken = action.payload?.userToken
      state.userName = action.payload?.userName
      state.userRole = action.payload?.userRole
      state.isStatus = action.payload?.isStatus
      state.isAdmin = action.payload?.isAdmin
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("userToken", state.userToken)
      localStorage.setItem("userEmail", state.userEmail)
      localStorage.setItem("userName", state.userName)
      localStorage.setItem("userRole", state.userRole)
      localStorage.setItem("isStatus", state.isStatus)
      localStorage.setItem("isAdmin", state.isAdmin)
    },
    removeToCart(state, action) {
      state.userId = ''
      state.userEmail = ''
      state.userToken = ''
      state.userName = ''
      state.userRole = ''
      state.isStatus = false
      state.isAdmin = false
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("userToken", state.userToken)
      localStorage.setItem("userEmail", state.userEmail)
      localStorage.setItem("userName", state.userName)
      localStorage.setItem("userRole", state.userRole)
      localStorage.setItem("isStatus", state.isStatus)
      localStorage.setItem("isAdmin", state.isAdmin)
    },
    addToLanguage(state, action) {
      state.languageCode = action.payload
      localStorage.setItem("languageCode", state.languageCode)
    },
    removeToLanguage(state, action) {
      state.languageCode = ''
      localStorage.setItem("languageCode", state.languageCode)
    },

  },
  extraReducers: {
  }
});

export const { addToCart, removeToCart, addToLanguage, removeToLanguage } = authReducer.actions;

export default authReducer.reducer;
