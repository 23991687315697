import React, { useState, useEffect, useRef, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { Navbar, Button } from "react-bootstrap";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./index.scss";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { Menu, Dropdown } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { removeToLanguage, removeToCart } from "../../reducers/authReducer";
import PeopleIcon from '@mui/icons-material/People';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MailIcon from '@mui/icons-material/Mail';

function HeaderMenu(props) {
  const dispatch = useDispatch();
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  function handleClick(e) {
    console.log('', e);
  }
  const history = useHistory();
  const location = useLocation();


  function userLogout() {
    dispatch(removeToCart());
    history.push("/login");
  }

  function changeLanguage() {
    dispatch(removeToLanguage());
    history.push("/language");
  }


  const [collapsed, setStatesss] = useState(false)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };



  function displayMenu() {
    let name;
    if (languageCode === 'desi') {
      name = 'Desi'
    } else if (languageCode === 'urdu') {
      name = 'Urdu'
    } else if (languageCode === 'en') {
      name = 'English'
    } else if (languageCode === 'ar') {
      name = 'Arabic'
    } else {
      name = 'Language Not Found!'
    }
    const breadcrumbs = window.location.pathname.split('-').join(' ').split('/')

    return (
      <div className="dFlex">
        <div className="dFlex" style={{ width: '100%', whiteSpace: 'pre', fontSize: '1.2rem', paddingLeft: '0px', textTransform: 'capitalize', color: '#212945' }}>
          {
            window.location.pathname.split('-').join(' ').split('/')[1] ?
              window.location.pathname.split('-').join(' ').split('/')[1] :
              'Dashboard'
          }
        </div>
        <div className="dFlex"><ChevronRightIcon style={{ fontSize: '24px' }} /></div>
      </div>
    )

  }

  return (
    <div className="ML-Header">

      <Menu onClick={handleClick} mode="vertical"
        inlineCollapsed={props.collapsed} className="sideMenuN">
        <div className={`navTophdng strNumnv logoHeader ${props.collapsed && 'navTophdngcollapsed'}`}>
          <span className="nameColor"><span className={`header_top-heading ${props.collapsed && 'hideHeaderName'}`}>AB Fitness</span>
            <span className={`syncIcon ${props.collapsed && 'syncColor'}`} onClick={() => props.toggleCollapsed({ toggleCollapsed })}>
              {React.createElement(props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
            </span>
          </span>
        </div>
    
        {userRole === 'admin' &&
          <>
            <Menu.Item
              style={{ background: window.location.pathname === '/' && '#ffffff1f' }}
              className="menuColor dFlex"
              key="item-1"
              icon={<DashboardIcon className="sideMenuIcon" />}
            >
              <Link to={{ pathname: "/", state: { from: "root" } }}>
                Dashboard
              </Link>
            </Menu.Item>
            <Menu.Item
              style={{ background: window.location.pathname === '/inquiries' && '#ffffff1f' }}
              className="menuColor dFlex"
              key="item-3"
              icon={<MailIcon className="sideMenuIcon" />}
            >
              <Link to={{ pathname: "/inquiries", state: { from: "root" } }}>
                Inquiries
              </Link>
            </Menu.Item>
            <Menu.Item
              style={{ background: window.location.pathname === '/team' && '#ffffff1f' }}
              className="menuColor dFlex"
              key="item-6" icon={<PeopleIcon className="sideMenuIcon" />}
            >
              <Link to={{ pathname: "/team", state: { from: "root" } }}>
                Team
              </Link>
            </Menu.Item>
          </>
        }
      </Menu>
      <Navbar className={`topNavBar ${props.collapsed && 'menuCollapsed'}`} bg="white" expand="lg">
        <div className="addAllIconCard">
          {displayMenu()}
        </div>
        <div className="headerRightCard">
          <Dropdown className="Header-waller-connect2" overlay={
            <Menu className="Header-dropdown-menu">
              <Menu.Item key="1">
                <Link onClick={userLogout}>Sign out</Link>
              </Menu.Item>
            </Menu>
          } trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
              <span>{userEmail} </span>
              <span><ExpandMoreIcon /></span>
            </a>
          </Dropdown>

        </div>
      </Navbar>
    </div>
  );
}

export default HeaderMenu;
