import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useGetUserQuery } from '../../services/sessions';
import { removeToCart } from "../../reducers/authReducer";
import { Button, Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
function Sessions() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const checkuser = useGetUserQuery({
    userId: userId
  }, { count: 1 }, { refetchOnMountOrArgChange: true })


  const [getSessionModal, setSessionModal] = useState(false)



  useEffect(() => {
    if (userId && userToken) {
      if (checkuser?.isSuccess) {
        if (checkuser?.data?.user) {
          if (checkuser?.data?.user?.user_token !== userToken) {
            setSessionModal(true)
          }
          if (checkuser?.data?.user?.status === 0) {
            setSessionModal(true)
          }
        }
      }
    }
  }, [checkuser]);


  function sessionLogin() {
    dispatch(removeToCart());
    setSessionModal(false)
  }


  return (
    <Modal
      className="image-update-modal"
      show={getSessionModal}
      animation={true}
      backdrop={'static'}
    >
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <h4 style={{ textAlign: 'center', padding: '20px' }}>Session has expired! continue to login</h4>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group" style={{ textAlign: 'center' }}>

              <Button
                size="sm"
                className="coloredButton"
                onClick={(() => sessionLogin())}>Login Again</Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );



}

export default Sessions;
