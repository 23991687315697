import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';

import { teamApi } from '../services/team';
import { loginApi } from '../services/login';
import { inquiryApi } from '../services/inquiries';
import { sessionsApi } from '../services/sessions';

import authReducer from "../reducers/authReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [inquiryApi.reducerPath]: inquiryApi.reducer,
    [sessionsApi.reducerPath]: sessionsApi.reducer,
    [teamApi.reducerPath]: teamApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loginApi.middleware).concat(inquiryApi.middleware).concat(sessionsApi.middleware).concat(teamApi.middleware)
})
setupListeners(store.dispatch)
