import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Login'],
  // keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
   
    loginUser: builder.mutation({
      query: (data) => {
        return {
          url: `login`,
          method: 'POST',
          body: data,
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),


  }),

})

export const { useLoginUserMutation } = loginApi