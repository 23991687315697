import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory } from "react-router-dom";
import { Button, Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import './index.scss';
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../reducers/authReducer";
import { useLoginUserMutation } from '../../services/login';
import Loader from '../../Helper/Loader';

function Login() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory();

  const [loginUser, loginresult] = useLoginUserMutation()
  const loginData = loginresult?.data?.data;
  const loginError = loginresult?.error?.data?.error;

  useEffect(() => {
    if (loginresult?.isSuccess) {
      dispatch(addToCart({
        userId: loginData?.id,
        userToken: loginData?.user_token,
        userEmail: loginData?.email,
        userName: loginData?.name,
        isStatus: loginData?.status,
        isAdmin: loginData?.admin,
        userRole: loginData?.user_role,
      }));
      // history.push('/dashboard');
    }
  }, [loginresult?.isSuccess]);



  return (
    <div className="loginStyle">
      {userToken && userId && <Redirect to="/" />}
      <div className="container">
        <div className="row loginRow">
          <div className="rowBox">
            <div className="loginHeader">
              <h2></h2>
              <h2>AB Fitness</h2>
            </div>
            <div>
              {loginError && <h6 className="alert alert-danger textMsgV"> {loginError}</h6>}
            </div>
            <form onSubmit={handleSubmit(loginUser)}>
              <div className="form-group">
                <label>Email Address</label>
                <input placeholder='Email Address' className="form-control" type="email" id="email"
                  aria-invalid={errors.email ? "true" : "false"}
                  {...register('email', { required: true })}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  placeholder='Password'
                  type="password"
                  className="form-control dlrdflInput"
                  id="password"
                  aria-invalid={errors.password ? "true" : "false"}
                  {...register('password', { required: true, minLength: 8, maxLength: 16 })} />
                {errors.password && errors.password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                {errors.password && errors.password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
                {errors.password && errors.password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
              </div>


              {loginresult.isLoading ?
                <Button className="dFlex w100 jc coloredButton" variant="warning" disabled>
                  <Spinner style={{ width: '1.5rem', height: '1.5rem', fontSize: '7px' }} animation="border" />
                </Button> : <Button className='coloredButton' style={{ width: '100%', justifyContent: 'center' }} variant="warning" type="submit">Login</Button>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
