import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import UsersSkeleton from "./UsersSkeleton";
import { useGetAllUsersQuery, useCreateUserMutation, useUpdateUserMutation } from '../../services/team';
import Pagination from "../Pagination";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { message, Tag, Menu, Dropdown, Switch as Clickable } from "antd";
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Moment from 'moment';
import Select from 'react-select';
import Loader from '../../Helper/Loader';
import EditIcon from '@mui/icons-material/Edit';

import Sessions from '../../Helper/Sessions';

const KEY = "ADD_PRODUCTS";
function Team() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [getSearchId, setSearchId] = useState("")
  const [getNewModal, setNewModal] = useState(false)
  const [getOthersModal, setOthersModal] = useState(false)
  const [getName, setName] = useState('')
  const [getUsername, setUsername] = useState('')
  const [getReference, setReference] = useState('')

  const othersUserData = {
    name: getName,
    username: getUsername,
    reference: getReference
  }

  const usersInfo = useGetAllUsersQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const users = usersInfo?.data && usersInfo?.data?.users;
  const pagination = usersInfo?.data && usersInfo?.data?.pagination;



  const [createUser, createresult] = useCreateUserMutation()
  const [updateUser, updateresult] = useUpdateUserMutation()


  function ebtidaEModal() {
    setNewModal(true)
  }
  function closeNewModal() {
    setNewModal(false)
  }


  function othersEModal() {
    setOthersModal(true)
  }
  function closeOthersModal() {
    setOthersModal(false)
  }


  const [getUserUpdateModal, setUserUpdateModal] = useState(false)
  const [getUserUpdateData, setUserUpdateData] = useState()
  const [getUserRole, setUserRole] = useState('')
  function updateUserModal(data) {
    setUserUpdateModal(data.status)
    setUserUpdateData(data)
  }
  function closeUserUpdateModal() {
    setUserUpdateModal(false)
  }

  const bookItemData = {
    user_role: getUserRole,
    id: getUserUpdateData?.id,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      setNewModal(false)
      setOthersModal(false)
    }
    if (updateresult?.isSuccess) {
      setUserUpdateModal(false)
    }
  }, [updateresult, createresult]);



  const customStyles = {
    control: styles => ({
      ...styles,
      width: 'auto'
    }),
    option: styles => ({
      ...styles,
      width: 'auto'
    }),
    menu: styles => ({
      ...styles,
      width: 'auto'
    })

  };

  function displayUserRole(role) {
    let name;
    if (role === 'admin') {
      name = 'Master'
    } else if (role === 'task') {
      name = 'Task'
    } else if (role === 'default') {
      name = 'Default'
    } else {
      name = role
    }
    return name;

  }


  function statusText(status) {
    if (status === 1) {
      return 'Are you sure to disable this user?'
    } else {
      return 'Are you sure to enable this user?'
    }
  }

  function adminText(admin) {
    if (admin === 1) {
      return 'Are you sure to change admin to default this user?'
    } else {
      return 'Are you sure to change to admin this user?'
    }
  }



  return (
    <div className="tabel_cards">
      {Sessions()}
      <Modal className="user-page-modal" show={getUserUpdateModal} onHide={closeUserUpdateModal} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Access</label>
                <select className="form-control dlrdflInput" onChange={(e) => setUserRole(e.target.value)}>
                  <option value={getUserUpdateData?.user_role} selected disabled>{getUserUpdateData?.user_role}</option>
                  <option value="task">Task</option>
                  <option value="admin">Master</option>
                  <option value="default">Default</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Button style={{ width: '100%', justifyContent: 'center' }} variant="warning" size="sm" className="coloredButton" onClick={() => !updateresult.isLoading && updateUser(bookItemData)}>
                  {updateresult.isLoading ? <Loader /> : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>




      <Modal className="user-page-modal" show={getNewModal} onHide={closeNewModal} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(createUser)}>
            {createresult?.error?.data?.error &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px' }}>
                <span className="alert alert-danger">{createresult?.error?.data?.error}</span>
              </div>
            }
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Role</label>
                  <select className="form-control dlrdflInput"
                    id="user_role"
                    aria-invalid={errors.user_role ? "true" : "false"}
                    {...register('user_role', { required: true })}>
                    <option value="" selected disabled>Select Role</option>
                    <option value="default">Default</option>
                    <option value="task">Task</option>
                    <option value="admin">Master</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" className="form-control dlrdflInput"
                    id="email"
                    aria-invalid={errors.email ? "true" : "false"}
                    {...register('email', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Password</label>
                  <input type="password" autoComplete="off" className="form-control dlrdflInput"
                    id="password"
                    aria-invalid={errors.password ? "true" : "false"}
                    {...register('password', { required: true, minLength: 8, maxLength: 16 })} />
                  {errors.password && errors.password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                  {errors.password && errors.password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
                  {errors.password && errors.password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <Button variant="warning" className="coloredButton" type="submit" style={{ width: '100%', justifyContent: 'center' }}>
                    {createresult.isLoading ? <Loader /> : 'Create'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>


      <div className="container">
        <div className="row cardList__margin">
          <div className="col-md-12">
            <div className="storeCardH BrdrRound">

              <div className="storeCardB">
                <div className="updateButton" style={{display: 'flex', alignItems: 'center'}}>
                  <div className='pr mr10' style={{position: 'relative'}}>
                    <input className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                    {usersInfo?.isFetching &&
                      <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                    }
                    <SearchIcon className="table_search_icon" />
                  </div>
                  <div className="table_search_card">
                    <div>

                      <Button onClick={() => ebtidaEModal(true)} className="coloredButton" variant="primary">
                        Add New
                      </Button>
                    </div>
                  </div>
                </div>


                <div className="tabelOverflow">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>User</th>
                        <th>Mobile</th>
                        <th>Role</th>
                        <th>Admin</th>
                        <th>Created</th>
                        <th className="sticky-column" style={{ textAlign: 'right' }}>
                          Status
                        </th>

                      </tr>
                    </thead>
                    {users?.length === 0 ?
                      <h5 className="data_not-found">No data available in table</h5>
                      : null}
                    <tbody>
                      <>
                        {usersInfo?.isLoading &&
                          <UsersSkeleton />
                        }
                      </>
                      {users?.map((item, i) =>
                        <tr key={i}>
                          <td>
                            <div>{item.id}</div>
                          </td>
                          <td style={{ position: 'relative' }}>
                            <Link to={`/member/${item.id}?t=active_membership`}>
                              <Tag className="booksTag" color={item.membership ? "#f7ee23" : ''}>
                                <span style={{ color: '#007bff' }}>{item.name && <span>{item.name} | </span>}{item.email}</span>
                              </Tag>
                              {/* <span style={item.membership && { background: '#f7ee23', padding: '0px 5px 2px 5px' }}>
                                {item.name && <span>{item.name} | </span>}   {item.email}
                              </span> */}
                            </Link>
                          </td>

                          <td>
                            <div>{item.phone}</div>
                          </td>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div>
                                {displayUserRole(item.user_role)}
                              </div>

                              {item.email !== 'admin@admin.com' &&
                                <a onClick={e => e.preventDefault()}>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={() => updateUserModal({
                                      id: item.id,
                                      status: true,
                                      user_role: item.user_role,
                                    })}
                                  >
                                    <MoreVertIcon style={{ fontSize: '18px' }} />
                                  </IconButton>
                                </a>
                              }
                            </div>
                          </td>

                          <td>
                            <Tag style={{ cursor: 'pointer' }} className="booksTag" color={item.admin === 1 ? '#72c585' : '#eb8188'}
                              onClick={() => {
                                if (window.confirm(adminText(item.admin)))
                                  item.email !== 'admin@admin.com' && updateUser({
                                    id: item.id,
                                    admin: item.admin ? 0 : 1,
                                    admin_update: 'admin_update',
                                    userId: userId,
                                    userToken: userToken,
                                    userRole: userRole
                                  })
                              }
                              }
                            >
                              <small>{updateresult?.isLoading && updateresult?.admin_update ? <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', margin: '0 0px -3px' }} animation="border" /> : item.admin === 1 ? 'true' : 'false'}</small>
                            </Tag>

                          </td>
                          <td>
                            <div>{Moment(item.createdAt).format('MMM Do YY')}</div>
                          </td>
                          <td className="sticky-column" style={{ textAlign: 'right' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>

                              <Tag style={{ cursor: 'pointer', margin: 0 }} className="booksTag" color={item.status === 1 ? '#72c585' : '#eb8188'}
                                onClick={() => {
                                  if (window.confirm(statusText(item.status)))
                                    item.email !== 'admin@admin.com' && updateUser({
                                      id: item.id,
                                      status: item.status ? 0 : 1,
                                      status_update: 'status_update',
                                      userId: userId,
                                      userToken: userToken,
                                      userRole: userRole
                                    })
                                }
                                }
                              >
                                <small>{updateresult?.isLoading && updateresult?.status_update ? <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', margin: '0 0px -3px' }} animation="border" /> : item.status === 1 ? 'Active' : 'Disabled'}</small>
                              </Tag>

                            </div>
                          </td>
                        </tr>
                      )
                      }
                    </tbody>
                  </table>
                </div>
                <div className="newFlexPagi">
                  <Pagination page={page} limit={limit} pagination={pagination} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
