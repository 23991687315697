import React, { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Login from './components/Login';
import Admin from './Private/admin';
import NotFound from './NotFound';
import Inquiries from './components/Inquiries';
import './App.css';
import { useSelector } from 'react-redux'
import Team from "./components/Team";

function App() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  return (
    <div className="App">

      <Router>
        <Switch>
          <Route exact path="/">
            <Admin component={Inquiries} />
          </Route>
          <Route exact path="/inquiries">
            <Admin component={userRole === 'admin' ? Inquiries : NotFound} />
          </Route>
          <Route exact path="/team">
            <Admin component={userRole === 'admin' ? Team : NotFound} />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route>
            <Admin component={NotFound} />
          </Route>
        </Switch>
      </Router>


    </div>
  );
}

export default App;
