import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import UsersSkeleton from "./UsersSkeleton";
import { useGetAllInquiriesQuery, useCreateInquiriesMutation, useUpdateInquiriesMutation, useDeleteInquiriesMutation, useDownloadInquiriesMutation } from '../../services/inquiries';
import Pagination from "../Pagination";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { message, Tag, Menu, Dropdown, Switch as Clickable } from "antd";
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Moment from 'moment';
import Select from 'react-select';
import Loader from '../../Helper/Loader';
import StarsIcon from '@mui/icons-material/Stars';
import { CSVLink, CSVDownload } from "react-csv";
const KEY = "ADD_PRODUCTS";
function Inquiries() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [getSearchId, setSearchId] = useState("")
  const [getNewModal, setNewModal] = useState(false)
  const [getOthersModal, setOthersModal] = useState(false)
  const [getName, setName] = useState('')
  const [getUsername, setUsername] = useState('')
  const [getReference, setReference] = useState('')

  const othersUserData = {
    name: getName,
    username: getUsername,
    reference: getReference
  }



  const [downloadcsv, downloadresult] = useDownloadInquiriesMutation()


  const [getDownloadData, setDownloadData] = useState([])


  useEffect(() => {
    if (downloadresult?.isSuccess) {
      setDownloadData(downloadresult?.data?.results)
    }
  }, [downloadresult]);

  const csvData = getDownloadData;



  const inquiryInfo = useGetAllInquiriesQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const inquiries = inquiryInfo?.data && inquiryInfo?.data?.inquiries;
  const pagination = inquiryInfo?.data && inquiryInfo?.data?.pagination;


  const [createUser, createresult] = useCreateInquiriesMutation()
  const [updateUser, updateresult] = useUpdateInquiriesMutation()


  function ebtidaEModal() {
    setNewModal(true)
  }
  function closeNewModal() {
    setNewModal(false)
  }


  function othersEModal() {
    setOthersModal(true)
  }
  function closeOthersModal() {
    setOthersModal(false)
  }


  const [getUserUpdateModal, setUserUpdateModal] = useState(false)
  const [getUserUpdateData, setUserUpdateData] = useState()
  const [getUserRole, setUserRole] = useState('')
  function updateUserModal(data) {
    setUserUpdateModal(data.status)
    setUserUpdateData(data)
  }
  function closeUserUpdateModal() {
    setUserUpdateModal(false)
  }

  const bookItemData = {
    user_role: getUserRole,
    id: getUserUpdateData?.id,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      setNewModal(false)
      setOthersModal(false)
    }
    if (updateresult?.isSuccess) {
      setUserUpdateModal(false)
    }
  }, [updateresult, createresult]);



  const customStyles = {
    control: styles => ({
      ...styles,
      width: 'auto'
    }),
    option: styles => ({
      ...styles,
      width: 'auto'
    }),
    menu: styles => ({
      ...styles,
      width: 'auto'
    })

  };

  function displayUserRole(role) {
    let name;
    let color;
    if (role === 'closed') {
      name = 'Closed'
      color = '#eb8188'
    } else if (role === 'open') {
      name = 'Open'
      color = '#72c585'
    } else {
      name = 'Open'
      color = '#72c585'
    }
    return (
      <Tag className="booksTag" color={color}>{name}</Tag>
    )

  }



  return (
    <div className="tabel_cards">
 

      <div className="container">
        <div className="row cardList__margin">
          <div className="col-md-12">
            <div className="storeCardH BrdrRound">

              <div className="storeCardB">
                <div className="updateButton">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='pr mr10'>
                      <input className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                      {inquiryInfo?.isFetching &&
                        <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                      }
                      <SearchIcon className="table_search_icon" />
                    </div> 
                  </div>
              
                </div>


                <div className="tabelOverflow">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Ref.</th>
                        <th>Message</th>
                        <th>Date</th>
                        <th className="sticky-column" style={{ textAlign: 'right' }}>
                          Manage
                        </th>

                      </tr>
                    </thead>
                    {inquiries?.length === 0 ?
                      <h5 className="data_not-found">No data available in table</h5>
                      : null}
                    <tbody>
                      <>
                        {inquiryInfo?.isLoading &&
                          <UsersSkeleton />
                        }
                      </>
                      {inquiries?.map((item, i) =>
                        <tr key={i}>
                          <td>
                            <div>{item.id}</div>
                          </td>
                          <td>
                            <div>{item.name}</div>
                          </td>
                          <td>
                            <div>{item.email}</div>
                          </td>
                          <td>
                            <div>
                              <div> {item.phone}</div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <div> {item.reference}</div>
                            </div>
                          </td>
                          <td>
                            <div style={{ minWidth: '0px', whiteSpace: 'normal', maxWidth: '500px' }}>{item.message}</div>
                          </td>
                          <td>
                            <div>
                              <div> {item.createdAt}</div>
                            </div>
                          </td>
                          <td className="sticky-column" style={{ textAlign: 'right' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                              {/* {displayUserRole(item.state)} */}

                              <Button disabled={item.state === 'closed' ? 'true' : false} variant={'danger'} size="sm"
                                onClick={() => updateUser({
                                  id: item.id,
                                  state: item.state === 'closed' ? 'open' : 'closed',
                                })}
                              >
                                {(updateresult?.isLoading) && (updateresult?.originalArgs?.id) === item.id ? <Loader /> : item.state === 'closed' ? 'Closed' : 'Close'}
                              </Button>
                              {/* <div>
                                {(updateresult?.isLoading) && (updateresult?.originalArgs?.id) === item.id ? <Loader /> :
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={() => updateUser({
                                      id: item.id,
                                      state: item.state === 'closed' ? 'open' : 'closed',
                                    })}
                                  >
                                    <StarsIcon style={{ color: item.state === 'closed' ? '#72c585' : '#eb8188' }} />
                                  </IconButton>
                                }
                              </div> */}

                            </div>
                          </td>
                        </tr>
                      )
                      }
                    </tbody>
                  </table>
                </div>
                <div className="newFlexPagi">
                  <Pagination page={page} limit={limit} pagination={pagination} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inquiries;
