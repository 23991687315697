import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const inquiryApi = createApi({
  reducerPath: 'inquiryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Inquiry'],
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    getAllInquiries: builder.query({
      query: (data) => ({
        url: `inquiries/?page=${data.page}&size=${data.size}&searchid=${data.searchid}`,
        method: 'GET',
        headers: {
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Inquiry'],
    }),


    getCareerInquiries: builder.query({
      query: (data) => ({
        url: `inquiries/${'career'}?page=${data.page}&size=${data.size}&searchid=${data.searchid}`,
        method: 'GET',
        headers: {
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Inquiry'],
    }),

    createInquiries: builder.mutation({
      query: (data) => {
        return {
          url: `inquiries`,
          method: 'POST',
          body: data,
          headers: {
            'X-User-Token': localStorage.getItem('userToken'),
            'X-User-Id': localStorage.getItem('userId'),
            'X-User-Role': localStorage.getItem('userRole'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Inquiry'],
    }),

    updateInquiries: builder.mutation({
      query: (data) => {
        return {
          url: `inquiries/${data.id}`,
          method: 'PUT',
          body: data,
          headers: {
            'X-User-Token': localStorage.getItem('userToken'),
            'X-User-Id': localStorage.getItem('userId'),
            'X-User-Role': localStorage.getItem('userRole'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Inquiry'],
    }),

    deleteInquiries: builder.mutation({
      query: (data) => {
        return {
          url: `inquiries/${data.id}`,
          method: 'DELETE',
          body: data,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Inquiry'],
    }),


    downloadInquiries: builder.mutation({
      query: (data) => ({
        url: `inquiries/${data.userId}/download`,
        method: 'POST',
        headers: {
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Inquiry'],
    }),



  }),

})

export const { useGetAllInquiriesQuery, useDownloadInquiriesMutation, useGetCareerInquiriesQuery, useCreateInquiriesMutation, useDeleteInquiriesMutation, useUpdateInquiriesMutation } = inquiryApi